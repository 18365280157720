app.service('searchViews', function (loading) {

	function init(){
		var isInit = false;
		var $input = $('#search-view');
		$input.val();
		var views = [];
		$('#menu a').each(function(){
			var $this = $(this);
			var url = $this.attr('href');
			if(url.indexOf('.ucm')>0){
				var parents = [];
				var $parents = $this.parents('li');
				$parents.each(function(){
					var $child = $(this).children('a');
					if($child.length){
						parents.push($child.text());
					}
				});
				views.push({
					url: url,
					label: $this.text().trim(),
					parents: parents.reverse()
				});
			}
		});
		$input.autocomplete(views,{
			matchContains:true,
			scrollHeight:250,
			resultsClass: "ac_results search-view",
			formatItem: function (data) {
				return data.label+'<small>'+data.parents.join(' -> ')+'</small>';
			},
			formatResult: function (data, value) {

			}
		}).result(function (event, data, formatted) {
			$input.val('');
			loading.redirect(data.url);
		}).blur(function () {
			$input.val('');
		});
	}

	return {
		init
	}

});
