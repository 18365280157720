function newApp(){
	// priority running
	var priority = {
		builtIn: 0,
		config: 10,
		service: 20
	};

	/*
		user services
		built-in services

		create in phase add new service
		use to run in phase .run()
		objects in object
	*/
	var fns = {
		/*
			set global API
			run for every services and save name of this service
			@param o1: 'api name' || api (required)
			@param o2: api
		 */
		'$global': {
			fn: function(parentName){
				return function(o1, o2){
					var api = o2 || o1;
					var name = o2 ? o1 || parentName : parentName;
					window.app[name] = api;
				}
			},
			deeps: []
		}
	};

	/*
		lists of services and it`s state
	 */
	var list = {
		/*
			built-in services on special character
			list of {}
		*/
		builtInServices: [
			{name: '$global', priority: priority.builtIn}
		],
		/*
			list of all services
			list of {}
		*/
		services: [],
		/*
			in phase .run();
			services sorting and ready to run; clear table after running all services
			list of names
		*/
		toRun: [],
		/*
			services after .run()
			list of names
		*/
		running: [],
		/*
			in phase .run()
			services are save to print if loop dependencies
			list of names
		 */
		inRun: []
	};

	function service(){
		addService(arguments, priority.service);
	}
	function config(){
		addService(arguments, priority.config);
	}

	/*
		add new service to list of services
	 */
	function addService(args, priority){
		register(args);

		var o = {
			name: args[0],
			priority: priority
		};
		list.services.push(o);
	}
	function register(args){
		var fn = args.length == 3 ? args[2] : args[1],
			override = args.length == 3 ? args[1] : false,
			name = args[0],
			deeps = fn.toString().replace(/\s|\t|\r|\n/g,'').match(/^function\(([^\)]*)/)[1];

		deeps = deeps ? deeps.split(',') : [];
		if(!override && fns[name]){
			error('service ' + name + ' exists');
		}else if(deeps.indexOf(name) != -1){
			error('dependency ' + name + ' is te same function');
		}else{
			if(override && fns[name]){
				msg('service ' + name + ' override');
				list.running.splice(list.running.indexOf(name), 1);
			}else if(override && !fns[name]){
				error('can`t override, not existing service ' + name);
			}
			fns[name] = {
				fn: fn,
				deeps: deeps
			}
		}
	}
	function run(){
		list.toRun = sortByDependenciesAndPriority(list.services);
		loop(list.toRun);
		list.toRun = [];
	}
	function sortByDependenciesAndPriority(tab){
		return tab.map(function(o){
			return o.name;
		});
	}
	function loop(tab, inDep){
		for(var i=0;i<tab.length;i++){
			var name = tab[i],
				fn_ = fns[name];

			// built-in services
			if(['$global'].indexOf(name) != -1){
				continue;
			}

			// fn not registered
			if(fn_.deeps.length){
				for(var j=0;j<fn_.deeps.length;j++){
					if(!fns[fn_.deeps[j]]){
						error('service ' + fn_.deeps[j] + ' not registered');
					}
				}
			}
			// fn == fnRun => error
			if(list.inRun.indexOf(name) != -1){
				list.inRun.push(name);
				list.inRun.splice(0, list.inRun.indexOf(name));
				error('dependency loop: ' + list.inRun.toString().replace(/,/g,' - '));
			}
			// fn are not in list.running
			if(list.running.indexOf(name) == -1){
				/*
					are not in built-in  &&  are not in list.running
				*/
				if(list.builtInServices.indexOf(name) == -1 && !areDependenciesRunning(fn_.deeps)){
					list.inRun.push(name);
					loop(fn_.deeps, true);
				}
				//msg('running service ' + name);
				fn_.fn = fn_.fn.apply(this, getReferencesOfDependencies(fn_.deeps, name));
				list.running.push(name);
				list.inRun.splice(list.inRun.indexOf(name), 1);
				list.services.splice(list.services._indexOf('name', name), 1);
			}
			list.inRun = inDep ? list.inRun : [];
		}
	}
	function getReferencesOfDependencies(deeps, parentName){
		var result = [];
		for(var i=0;i<deeps.length;i++){
			var name = deeps[i];
			// built-in service
			if(list.builtInServices._indexOf('name', name) != -1){
				result[i] = fns[name].fn(parentName);
			// user service
			}else if(list.running.indexOf(name) != -1){
				result[i] = fns[name].fn;
			}else{
				error('service ' + name + ' not running');
			}
		}
		return result;
	}
	function areDependenciesRunning(tab){
		for(var i=0;i<tab.length;i++){
			if(list.running.indexOf(tab[i]) == -1){
				return false;
			}
		}
		return true;
	}
	Array.prototype._indexOf = function(k, v){
		for(var i=0;i<this.length;i++){
			var o = this[i];
			if(o[k] && o[k] == v){
				return i;
			}
		}
		return -1;
	};
	function error(txt){
		throw new Error(txt);
	}
	function msg(txt){
		try{
			console.info(txt);
		}catch(e){}
	}

	return {
		service: service,
		config: config,
		run: run/*,
		 only to jasmine test
		_fns: fns,
		list:list
		*/
	}
}

// create app
window.app = new newApp();
