/**
 * Lazy Google reCAPTCHA v2
 * Asynchronicznie doczytuje api.js przy pierwszym wywołaniu add()
 */
app.service('recapcha', function (modal) {
    let isLoaded = false, isLoading = false;
    let widgetsToRender = [];
    let widgetsRendered = [];
    const captchaTypes = {
        RECAPTCHA_V2_CHECKBOX: 'RECAPTCHA_V2_CHECKBOX',
        RECAPTCHA_ENTERPRISE_CHECKBOX: 'RECAPTCHA_ENTERPRISE_CHECKBOX'
    };
    let recaptchaApi;

    window.recaptchaReadyCallback = function handleRecaptchaReadyCallback() {
        widgetsToRender.forEach(widget => render(widget));
    };

    function add(captchaId, siteKey, type) {
        if (!captchaTypes[type]) return;
        setRecaptchaApiByType(type);

        if (!isLoaded) {
            loadRecaptchaOnce();
        }

        if (isLoading) {
            widgetsToRender.push({ captchaId, siteKey, type });
        } else {
            render({ captchaId, siteKey, type });
        }
    }

    function reset(captchaId) {
        if (widgetsRendered[captchaId]) {
            getRecaptchaObjByType(widgetsRendered[captchaId].type).reset(widgetsRendered[captchaId].widgetId);
        }
    }

    function setRecaptchaApiByType(type) {
        if (type === captchaTypes.RECAPTCHA_V2_CHECKBOX) {
            recaptchaApi = 'https://www.google.com/recaptcha/api.js?onload=recaptchaReadyCallback&render=explicit';
        } else if (type === captchaTypes.RECAPTCHA_ENTERPRISE_CHECKBOX) {
            recaptchaApi = 'https://www.google.com/recaptcha/enterprise.js?onload=recaptchaReadyCallback&render=explicit';
        }
    }

    function getRecaptchaObjByType(type) {
        if (type === captchaTypes.RECAPTCHA_V2_CHECKBOX) {
            return grecaptcha;
        } else if (type === captchaTypes.RECAPTCHA_ENTERPRISE_CHECKBOX) {
            return grecaptcha.enterprise;
        }
    }

    function loadRecaptchaOnce() {
        if (!isLoading) {
            isLoading = true;
            loadRecaptcha()
                .then(() => {
                    isLoaded = true;
                    isLoading = false;
                });
        }
    }

    function loadRecaptcha() {
        return $.getScript(recaptchaApi)
            .fail(() => {
                modal.alert("Wystąpił błąd ładowania modułu reCAPTCHA. Odśwież stronę aby spróbować ponownie lub zgłoś błąd korzystając z formularza na stronie.");
            });
    }

    function checkValidModalCaptcha($id) {
        const $textArea = $id.find('textarea');
        const $parentForm = $id.closest('form');
        if ($parentForm.length) {
            if ($id.hasClass('validate-error')) {
                $parentForm.validate().element($textArea);
            }
        }
    }

    function render({ captchaId, siteKey, type }) {
        let $captcha = $(`#${captchaId}`);
        let $widget = $captcha.find('.widget');
        let $info = $captcha.find('.info');
        let $error = $captcha.find('.error-message');
        const submitOnVerify = $captcha.data('submit');

        $captcha.removeAttr('hidden');
        let widgetId = getRecaptchaObjByType(type).render($widget.get(0), {
            'sitekey': siteKey,
            'hl': 'pl',
            'callback': function verifyCallback() {
                checkValidModalCaptcha($captcha);
                $error.remove();
                if (submitOnVerify) {
                    $captcha.closest('form').submit();
                }
            }
        });
        widgetsRendered[captchaId] = { widgetId, type };
        $info.html(`W trosce o bezpieczeństwo Twoich danych prosimy zaznacz <strong>"Nie jestem robotem"</strong>.`);
    }

    return {
        add,
        reset
    };
});