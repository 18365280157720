
app.service('modalPage',function(loading, broker, modal, ajaxPage){
	var savedUrl, _isOpen=false;

	function load(url){
		savedUrl = url;
		loading.start();
		$.ajax({
			url: url,
			success:function(html){
				loading.stop();
				open(html);
			},
			error:function(){
				loading.stop();
				modal.alert('Błąd przetwarzania danych');
			}
		});
	}
	function isOpen(){
		return _isOpen;
	}
	function reload(){
		load(savedUrl);
	}
	function setFormAjax($el, params){
		var options = $.extend({
			beforeSubmit: function(){
				loading.start();
			},
			success:function(html){
				loading.stop();
				openCallback(html);
			},error:function(){
				modal.alert('Wystąpił błąd w wysyłaniu danych. Strona zostanie odświeżona', function(){
					loading.reload();
				});
			}
		}, params);
		$el.ajaxForm(options);
	}
	function openCallback(html){
		if(html){
			$('#modal-page').children().not('.remodal-close').remove();
			$('#modal-page').append(html);
			broker.trigger('MODAL_PAGE_RELOAD');
		}else{
			_isOpen = true;
			broker.trigger('MODAL_PAGE_SHOW');
		}
		if(html && html.indexOf('ajax-reload="true"') > -1){
			ajaxPage.reload();
			//broker.trigger('RELOAD_AJAX_CONTENT');
		}
		if (html && html.indexOf('auto-close="true"') > -1) {
			broker.trigger('MODAL_AUTO_CLOSE');
			modal.close();
		}
		setFormAjax($('#modal-list-form, #modal-edit-form'), {url:savedUrl});
	}
	function open(html){
		if(html){
			modal.generate({
				id:'modal-page',
				content: html,
				close:true,
				closeOnEscape:true,
				closeOnOutsideClick:false,
				callbackClose: function(){
					_isOpen = false;
					broker.trigger('MODAL_PAGE_CLOSE');
				},
				callbackOpen: function(){
					openCallback();
				}
			});
		}else{
			modal.open('modal-page');
		}
	}
	return {
		load, open, isOpen, reload, setFormAjax
	}
});
