app.service('editForm',function(){

	function init(el){
		var $self=$(el);
		if($self.length){
			$('input', $self).on('keyup keydown', function(event){
				if( event.keyCode === 13 ) {
					event.preventDefault();
				}
			});

			$('.group-input input[maxlength]', $self).each(function () {
				$(this).on('change keyup keydown paste', function () {
					setCountInput($(this));
				});
				setCountInput($(this));
			});

			$('.group-wysiwyg .js-count-text', $self).each(function () {
				var $count = $(this);
				var max = $count.attr('data-max') / 1;
				var name = $count.closest('.group').find('textarea').attr('name').replace("_","");
				var instance = false;
				try {
					setInterval(function () {
						if (!instance) {
							if (typeof FCKeditorAPI === 'object') {
								instance = FCKeditorAPI.GetInstance(name);
							}
						} else {
							var $tmp = $('<div>' + instance.GetHTML(true) + '</div>').text();
							var count = $tmp.length;
							$count.attr('data-count', count);
							if (count > max) {
								$count.addClass('max');
							} else {
								$count.removeClass('max');
							}
						}
					},1000);
					}catch(e){ console.log(e)}
			});
		}
	}

	function setCountInput($this) { 
		var $text = $this.closest('.group-input').find('.js-count-text');
		$text.attr('data-count', $this.val().length);
	}
	return {
		init
	}
});
