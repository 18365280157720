
app.service('tabsView', function (broker, modal){
    var path = document.location.href;
    path = path.substr(path.lastIndexOf('/') + 1);
    if (path.indexOf('&')>-1) {
        path = path.substr(0, path.indexOf('&'));
    }
    if (path.indexOf('#')>-1) {
        path = path.substr(0, path.indexOf('#'));
    }
    var $li, $tabs, index;
    var $activeTab, loadingTab=false;
    var $tabAction, $tabGhost, savedHeight = 0;
    var stage;
    // _loadedForm jest ustawiany globalnie

    function load() {
        if (!loadingTab) {
            $activeTab.addClass('loading');
            loadingTab = true;
            $.ajax({
                url: $activeTab.attr('data-url'),
                success: function (html) {
                    $activeTab.html(html);
                    $tabGhost = false;
                    broker.trigger('AJAX_CONTENT_LOADED', $activeTab);
                    _loadedForm = $activeTab.find('form').serialize();
                },
                error: function () {
                    modal.alert('Błąd ładowania zakładki', function () { 
                        $activeTab.html(`<div class="error-message">Zakładka nie załadowała się poprawnie, prawdopodobnie wystąpił błąd. Zobacz w logach</div>`);
                    });
                },
                complete: function () {
                    loadingTab = false;
                    $activeTab.removeClass('loading');
                }
            });
        }
    }
    function click(c) {
        index = c;
        function go() { 
            if (!($li.eq(index).length && $tabs.eq(index).length)) {
                index = 0;
            }
            $li.removeClass('active').eq(index).addClass('active');
            $activeTab = $tabs.removeClass('active').eq(index);
            $activeTab.addClass('active');
            if (index != 0) {
                stage[path] = index;
            } else { 
                delete stage[path];
            }
            localStorage.setItem('tabs', JSON.stringify(stage));
            if ($activeTab.attr('data-url')) {
                load();
            }
        }
        app.fckUpdate();
        if (loadingTab) {
            return;
        } else if (($activeTab && typeof _loadedForm !== "undefined" && _loadedForm && _loadedForm != $activeTab.find('form').serialize()) || ($activeTab && $activeTab.find('form').attr('force-changed')=='true')) { 
            modal.generate({
                id: 'tabs-edit-coflift',
                content: 'Zmieniono treść zakładki. Niezapisane dane zostaną utracone.',
                buttons: [
					{
						text: 'Zapisz i idź dalej',
						click: function () {
							modal.close();
							broker.trigger('SAVE_TAB', {
								$activeTab,
								callback: function () {
									go();
								}
							});
						}
					},
                    {
                        text: 'Pozostań na obecnej zakładce',
                        click: function () {
                            modal.close();
                        }
                    },
                    {
                        text: 'Porzuć zmiany, idę dalej',
                        click: function () {
                            modal.close();
                            go();
                        }
                    }
                ]
            });
            return;
        } else {
            go();
        }
        
    }
    function createGhost() { 
        $tabAction = $('.tab-action', $activeTab);
        $tabAction.attr('data-name', $li.eq(index).text());
        $tabAction.wrap('<div class="tab-action-ghost" />');
        $tabGhost = $('.tab-action-ghost', $activeTab);
        savedHeight = 0;
    }
    function scroll() { 
        if ($tabAction && $tabAction.length && $tabGhost && $tabGhost.length) {
            if (savedHeight == 0) {
                savedHeight = $tabGhost.offset().top + $tabGhost.height();
                if (window.scrollY > savedHeight) {
                    $tabGhost.addClass('sticky');
                } else {
                    savedHeight = 0;
                }
            } else if (window.scrollY < savedHeight) {
                savedHeight = 0;
                $tabGhost.removeClass('sticky');
            }
        } else {
            savedHeight = 0;
        }
    }
	function init(el = $('.tabs-view')){
        var $self = $(el);
        
        broker.on('AJAX_CONTENT_LOADED', function () {
            createGhost();
            scroll();
            $('input.input-price', $self).keyfilter(/[\d,.]/);
        });
        if ($self.length) {
            $li = $self.find('.tabs-menu li');
            $tabs = $self.find('.tab');
            $self.on('click', '.tabs-menu li', function () {
                click($(this).index());
            });
            stage = localStorage.getItem('tabs');
            stage = stage ? JSON.parse(stage) : {};

            if ($('.tabs-menu li.active', $self).length){
                click($('.tabs-menu li.active', $self).index());
            } else {
                click(stage[path] ? stage[path] / 1 : 0);
            }

            window.addEventListener('scroll', function () { 
                window.requestAnimationFrame(function () {
                    scroll();
                });
            }); 
            
        }

	}
	return {
		init
	}
});
