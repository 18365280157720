app.service('fck', function ($global) {
    var history = [];

    function init($parent = $('body')) {
        $('textarea.wysiwyg-hidden', $parent).not('.init').each(function () {
            run({
                name: $(this).attr('data-name'),
                classId: $(this).attr('data-class-id'),
                height: $(this).attr('data-height') ? $(this).attr('data-height') : 300,
                width: $(this).attr('data-width') ? $(this).attr('data-width') : '100%'
            });
            $(this).addClass('init');
		});
    }
    function run(params) { 
        if (params && params.classId && params.name) {
            let hidden_textarea = document.getElementsByClassName(params.classId);
            if (hidden_textarea && hidden_textarea.length == 1) {
                let instanceFCK = new FCKeditor(params.name);
                instanceFCK.ForcePasteAsPlainText = true;
                instanceFCK.ClassId = params.classId;
                instanceFCK.BasePath = '/fck/fckeditor-v1/';
                instanceFCK.Value = hidden_textarea[0].value;
                instanceFCK.ToolbarSet = 'EuroLite';
                instanceFCK.Height = params.height;
                instanceFCK.Width = params.width;
                instanceFCK.Create();
            }
        }
    }
    function update() { 
        try {
            $('textarea.wysiwyg-hidden').each(function () {
                var instance = FCKeditorAPI.GetInstance($(this).attr('data-name'));
                instance.LinkedField.value = instance.GetHTML(true);
                instance.Events.FireEvent('OnAfterLinkedFieldUpdate');
            });
        } catch (error) {
        }
    }
    $global('fckUpdate', update);
	//$global('fckRun', run )
	return {
		init
	}
});
