/**
 * moduł trzymający informacje o walidacji
 * wersja okrojona dla widoków konsolowych
 *@module validate
 */
app.service('validate', function () {
    var rules = {
        login: {
            required: true
        },
        email: {
            required: true,
            email: true
        },
        password: {
            required: true
        },
        recaptcha: {
            required: true
        }
    };
    var messages = {
        login: {
            required: "Podaj login"
        },
        email: {
            required: "Wpisz adres e-mail",
            email: "Wpisz poprawny adres e-mail",
        },
        password: {
            required: "Podaj swoje hasło"
        },
        recaptcha: {
            required: "Prosimy zaznacz nie jestem robotem"
        }
    };
    var defaults = {
        ignore: ".ignore",
        errorElement: "label"
    };

    function setOptions(options) {
        var obj = {};
        for (var i in defaults) {
            obj[i] = defaults[i];
        }
        return $.extend(true, obj, options);
    }
    var tasks = {
        consoleForgotPassword: function (params) {
            var $form = params.form;
            var options = setOptions({
                submitHandler: function(form) {
                    params.success(form);
                },
                rules: {
                    login: rules.login,
                    email: rules.email,
                    "g-recaptcha-response": rules.recaptcha
                },
                messages: {
                    login: messages.login,
                    email: messages.email,
                    "g-recaptcha-response": messages.recaptcha
                }
            });
            $form.validate(options);
        },
        consoleLogin: function (params) {
            var $form = params.form;
            var options = setOptions({
                submitHandler: function (form) {
                    params.success(form);
                },
                rules: {
                    "j_username": rules.login,
                    "j_password": rules.password,
                    "g-recaptcha-response": rules.recaptcha
                },
                messages: {
                    "j_username": messages.login,
                    "j_password": messages.password,
                    "g-recaptcha-response": messages.recaptcha
                }
            });
            $form.validate(options);
        }
    };

    function task(name, params) {
        if (name && tasks[name]) {
            tasks[name](params);
        }
    }

    return {
        task: task
    };
});