app.service('redactor',function(){
    function init($parent = $('body')) {
        $('.redactor-textarea', $parent).not('.redactor-init').each(function () {
            var id = $(this).attr('id');
            run('#' + id);
            $(this).addClass('redactor-init');
            $('.js-open-fck', $(this).closest('.group-redactor')).on('click', function () {
                openFCK(id);
            });
        });
    }
    var inter = false;
    function openFCK(id) { 
        sessionStorage.setItem('openFCK', $R('#'+id, 'source.getCode'));
        windowObjectReference = window.open('product-edit-fckeditor.ucm', "FCK", "left=100,top=100,width=900,height=800");
        clearInterval(inter);
        inter = setInterval(function () {
            if (windowObjectReference.isClosed) {
                clearInterval(inter);
                let result = windowObjectReference.sessionStorage.getItem('saveFCK');
                windowObjectReference.sessionStorage.removeItem('saveFCK');
                windowObjectReference.close();
                $R('#' + id, 'destroy');
                $('#' + id).val(result);
                run('#' + id);
            }
        }, 300);
    }
    function run(id) { 
        $R(id, {
            lang: 'pl',
            toolbarFixed: false,
            imageFigure: false,
            imageResizable: false,
            imagePosition: false,
            //blockTags: ["link", "pre", "ul", "ol", "li", "p", "h1", "h2", "h3", "h4", "h5", "h6", "dl", "dt", "dd", "div", "table", "tbody", "thead", "tfoot", "tr", "th", "td", "blockquote", "output", "figcaption", "figure", "address", "section", "header", "footer", "aside", "article", "iframe"],
            removeScript: false,
            replaceTags: false,
            removeComments: false,
            removeScript: false,

            spellcheck: false,
            structure: false,

            autoparse: false,
            autoparseStart: false,
            autoparsePaste: false,
            autoparseLinks: false,
            autoparseImages: false,
            autoparseVideo: false,
            autoparseHttps: false,

            linkValidation: false,
            grammarly: false,
            notranslate: true,
            //pastePlainText: true,
            minHeight: '300px',
            maxHeight: '500px',
            maxWidth: '1000px',
            plugins: ['alignment', 'table', 'counter', 'fontcolor', 'fontsize', 'fullscreen', 'properties']
        });
    }
    return {
        init
    };
});
