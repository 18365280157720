(function($R)
{
    $R.lang['pl'] = {
        "format": "Formatuj",
        "image": "Obrazek",
        "file": "Plik",
        "link": "Link",
        "bold": "Pogrubienie",
        "italic": "Kursywa",
        "deleted": "Przekreślenie",
        "underline": "Podkreślenie",
        "superscript": "Indeks górny",
        "subscript": "Indeks dolny",
        "bold-abbr": "B",
        "italic-abbr": "I",
        "deleted-abbr": "S",
        "underline-abbr": "U",
        "superscript-abbr": "Sup",
        "subscript-abbr": "Sub",
        "lists": "Lista",
        "link-insert": "Wstaw link",
        "link-edit": "Edytuj link",
        "link-in-new-tab": "Otwórz link w nowej karcie",
        "unlink": "Usuń link",
        "cancel": "Anuluj",
        "close": "Zamknij",
        "insert": "Wstaw",
        "save": "Zapisz",
        "delete": "Usuń",
        "text": "Text",
        "edit": "Edytuj",
        "title": "Tytuł",
        "paragraph": "Zwykły tekst",
        "quote": "Cytat",
        "code": "Kod",
        "heading1": "Nagłówek 1",
        "heading2": "Nagłówek 2",
        "heading3": "Nagłówek 3",
        "heading4": "Nagłówek 4",
        "heading5": "Nagłówek 5",
        "heading6": "Nagłówek 6",
        "filename": "Nazwa",
        "optional": "opcjonalnie",
        "unorderedlist": "Lista punktowana",
        "orderedlist": "Lista numerowana",
        "outdent": "Zmniejsz wcięcie",
        "indent": "Zwiększ wcięcie",
        "horizontalrule": "Linia pozioma",
        "upload": "Przekazać plik",
        "upload-label": "Upuść pliki tutaj lub kliknij, aby przesłać",
        "upload-change-label": "Drop a new image to change",
        "accessibility-help-label": "Edytor tekstu",
        "caption": "Podpis",
        "bulletslist": "Kule",
        "numberslist": "Liczby",
        "image-position": "Pozycja",
        "none": "Żaden",
        "left": "Lewo",
        "right": "Prawa",
        "center": "Centrum",
        "undo": "Cofnij",
        "redo": "Ponów",
        "table": "Tabela",
        "insert-table": "Wstaw tabelę",
        "insert-row-above": "Wstaw wiersz powyżej",
        "insert-row-below": "Wstaw wiersz poniżej",
        "insert-column-left": "Wstaw kolumnę z lewej",
        "insert-column-right": "Wstaw kolumnę z prawej",
        "add-head": "Wstaw nagłówek",
        "delete-head": "Usuń nagłówek",
        "delete-column": "Usuń kolumnę",
        "delete-row": "Usuń wiersz",
        "delete-table": "Usuń tabelę",
        "align": "Wyrównaj",
        "align-left": "Wyrównaj do lewej",
        "align-center": "Wyśrodkuj",
        "align-right": "Wyrównaj do prawej",
        "align-justify": "Wyjustuj",
        "fontcolor": "Kolor tekstu",
        "text": "Tekst",
        "highlight": "Podświetlenie",
        "size": "Rozmiar",
        "remove-size": "Usuń rozmiar",
        "fullscreen": "Pełny ekran",
        "properties": "Właściwości",
        "words": "słowa",
        "chars": "znaki"
    };
})(Redactor);