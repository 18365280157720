app.service('lastRevisions',function(){
	function load(el){
		$self = $(el);
		if($self.length){
			$.ajax({
				url: $self.data('url'),
				success: function(html) {
					$self.html(html);
					if($self.data('modal')){
						$self.find('table a').each(function(){
							var $this = $(this);
							$this.data('url', $this.attr('href'));
							$this.attr('href','#');
						}).click(function(){
							modalPage.load($(this).data('url'));
						});
					}

				}
			});
		}
	}
	return {
		load
	}
});
