app.service('captchaConsole', function (recapcha) {

    function init() {
        $('[data-toggle="captcha"]').each(function () {
            const id = $(this).attr('id');
            const sitekey = $(this).data('sitekey');
            const type = $(this).data('type');
            recapcha.add(id, sitekey, type);
        });
    }

    function reset(id) {
        recapcha.reset(id);
    }

    return {
        init,
        reset
    };
});