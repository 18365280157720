
app.service('html', function (build){
	var element=$('html');
	var agent = window.navigator.userAgent;

	function addClass(name){
		if(name && element.length && !element.hasClass(name)){
			element.addClass(name);
		}
	}
	function removeClass(name){
		if(name && element.length && element.hasClass(name)){
			element.removeClass(name);
		}
	}
	function toggle(name){
		if(name && element.length) {
			element.toggleClass(name);
		}
	}
	function add(addClassName, removeClassName){
		addClass(addClassName);
		removeClass(removeClassName);
	}
	function remove(removeClassName,addClassName){
		removeClass(removeClassName);
		addClass(addClassName);
	}
	function has(className){
		return (className && element.length && element.hasClass(className));
	}

	if(/Edge\/|Trident\/|MSIE /.test(agent)){
		add('ie-mode');
	} else if (/Chrome/.test(agent)) {
		add('chrome-mode');
	} else if (/Firefox/.test(agent)) {
		add('firefox-mode');
	}
	add(build.app);

	return {
		add,
		remove,
		has,
		toggle
	};
});
