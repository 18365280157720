/* moduł zawierający globalne nakładki na AJAX'y */
app.service('ajaxOverwrite', function (modal, console, loading) {

   
    $(document).ajaxError(function (event, jqXHR, settings, thrownError) {
        console.log('Błąd ajaxa: ' + settings.url);
        var statusCode = jqXHR.status;

        if ((statusCode === 403 || statusCode === 401) && settings.url.indexOf('customer_j_spring_security_check.ltr') == -1) {
            modal.alert("Twoja sesja wygasła lub zostałeś wylogowany. <br/>Strona zostanie odświeżona i pojawi się formularz logowania<br/>Prosimy zalogować się ponownie.", function () {
                loading.reload();
            });
        }
    });
    
});
