app.service('download', function($global, loading, modal, cookie) {
	var downloadInterwal;

	function setDownload(params) {
		return new Promise(function(resolve, reject) {
			loading.start();
			cookie.removeGlobal('fileDownload');
			$.fileDownload(params.url).done(function() {
				loading.stop();
				resolve();
			}).fail(function() {
				loading.stop();
				modal.alert('Nie udało się pobrać pliku');
				reject();
			});
		});
	}

	function globalDownload(params) {
		return new Promise(function(resolve, reject) {
			checkAvailability().then(function() {
				setDownload(params).then(function() {
					resolve();
				}, function() {
					reject();
				});
			}, function() {
				reject();
			});
		});
	}

	function checkAvailability(callback) {
		return new Promise(function(resolve, reject) {
			loading.start();
			$.ajax({
				url: '/cons/report-availability-checker.ucm',
				success: function(response) {
					response = $.trim(response);
					loading.stop();
					if (response == 'OK') {
						resolve();
					} else {
						modal.alert(response);
						reject();
					}
				},
				error: function() {
					loading.stop();
					reject();
				}
			});
		});
	}

	function init() {
		$('body').on('click','.js-button-download', function(){
			globalDownload({
				url: $(this).data('url')
			});
		});

	}
	$global('download', globalDownload);

	return {
		init,
		download: globalDownload
	}
});
