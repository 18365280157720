app.service('modal',function($global, console, loading, html){
	var alertModal=false;
	var alertCallback=false;
	function open(id, params){
		if(isExist(id)){
			var defaults={
				hashTracking: false
			};
			var modal = $('[data-remodal-id='+id+']').remodal($.extend(true,defaults,params));
			loading.stop();
			modal.open();
			return modal;
		}else{
			try{
				console.log('NIE ISTNIEJE MODAL: '+id);
			}catch(e){}
		}
	}
	function confirm(text, callback) { 
		generate({
			id: 'confirm',
			content: '<p>'+text+'</p>',
			buttons: [{
				text: 'Ok',
				cssClass: 'button-ok',
				click: function () { 
					close();
					callback();
				}
			}]
		});
	}
	function alert(text, callback){
		if(typeof(text)==='object'){
			$result=$('<ul/>');
			text.forEach(function(a,i){
				$result.append($('<li/>').text(a));
			})
			$('#alert p').html($result);
		}else{
			$('#alert p').html(text);
		}
		alertCallback=callback || false;
		loading.stop();
		alertModal.open();
	}
	function close(id){
		var $modal = $('.remodal-is-opened .remodal');
		if ($modal.length){
			$modal.remodal().close();
		}
	}
	function isOpen(id){
		if(isExist(id)){
			return $('[data-remodal-id='+id+']').remodal().getState()=='opened';
		}
		return false;
	}
	function isExist(id){
		return $('[data-remodal-id='+id+']').length ? true : false;
	}
	function destroy(id){
		try{
			$('[data-remodal-id='+id+']').remodal().destroy();
		}catch(e){}
	}
	function generate(params){
		loading.stop();
		var options={
			content:null,
			header:null,
			modifier:null,
			callbackOpen:null,
			callbackClose:null,
			closeOnEscape:true,
			close:true,
			show:true,
			id:null,
			overwrite:true,
			hashTracking: false,
			topLayer:false,
			buttons:{}
		};
		$.extend(true, options, params);
		if(options.overwrite && options.id){
			$('[data-remodal-id='+options.id+']').parent().remove();
		}
		var $html=$('<div class="remodal"></div>');
		if(options.id){
			$html.attr('id',options.id);
			$html.attr('data-remodal-id',options.id);
		}
		if(options.cssClass){
			$html.addClass(options.cssClass);
		}
		if(options.close){
			var closeButton=$('<button data-remodal-action="close" class="remodal-close"></button>');
			$html.append(closeButton);
		}
		if(options.header){
			$html.append('<h2>'+options.header+'</h2>');
		}
		if(options.content){
			$html.append(options.content);
		}
		if(options.buttons.length){
			var buttons=$('<div class="buttons"/>');
			$(options.buttons).each(function(i,n){
				var button=$('<button type="button"/>');
				if(n.text){
					button.text(n.text);
				}
				if(n.cssClass){
					button.addClass(n.cssClass);
				}
				if(n.click){
					button.click(function(){
						n.click();
					});
				}
				buttons.append(button).addClass('buttons-'+options.buttons.length);
			});
			$html.append(buttons);
		}
		$('body').append($html);
		var modalHandler=$html.remodal({
			hashTracking: false,
			modifier: options.modifier,
			closeOnEscape: !(options.closeOnEscape===false || options.close===false),
			closeOnOutsideClick: options.closeOnOutsideClick !== null ? options.closeOnOutsideClick : options.close
		});
		if(options.show){
			modalHandler.open();
		}
		if(options.callbackOpen){
			options.callbackOpen.call(modalHandler);
		}
		if(options.callbackClose){
			$(document).on('closed', $html, function (e) {
				options.callbackClose();
			});
		}
		return modalHandler;
	}

	function setElements(){
		alertModal=generate({
			cssClass:'alert-modal',
			id:'alert',
			show:false,
			content:'<p></p><button data-remodal-action="confirm" class="button-ok">OK</button>'
		});
		$(document).on('closed', alertModal, function (e) {
			if(alertCallback){
				alertCallback();
			}
		});
	}
	function setEvents(){
		$('body').on('click','.close-modal, .js-close-modal',function(event){
			close();
		});
	}
	function init(){
		setElements();
		setEvents();
	}

	$global('alert', alert);
	$global('confirm', confirm);
	$global('modalOpen', open);
	$global('modalClose', close);
	$global('modalGenerate', generate);

	return {
		init,
		open,
		alert,
		confirm,
		generate,
		close,
		isOpen,
		isExist,
		destroy
	};
});
