app.service('listForm',function(loading, console, modal){

	function init( options ){
		if (options.id) {
			var $self = $(options.id);

			function changeOrder(name, value = null) {
				$('input.field-name', $self).val(name);
				$('input.sort-order', $self).val(value);
				$self.submit();
			}
			function changePage(page){
				if(page>-1){
					$('input.page-number', $self).val(page);
					$self.submit();
				}else{
					modal.alert('Nie podano numeru strony')
				}
			}
			function changeItemsOnPage(pages) {
				$('input.itemsOnPage', $self).val(pages);
				$('.pagination', $self).first().data('delta');
				$self.submit();
			}
			if ($self.length) {
				$self.on('click', '.pagination a', function () {
					changePage($(this).data('page'));
				}).on('change', '.navigation-items-on-page select', function () {
					changeItemsOnPage($(this).val());
				}).on('click', 'th a.asc, th a.desc', function () {
					changeOrder($(this).data('name'), $(this).data('value'));
				}).on('change', '.filter-row-submit select', function () {
					$('#filters .button-search').click();
				}).on('change', '.filter-row-submit input', function () {
					$('#filters .button-search').click();
				}).on('click', '.navigation-go-to-page button', function () {
					var delta = $('.pagination', $self).first().data('delta') / 1;
					changePage($(this).prev().val() / 1 - 1 + delta);
				}).on('keypress keydown keyup', '.navigation-go-to-page input', function (event) {
					if (event.keyCode == 13 && $(this).val() > 0) {
						event.preventDefault();
						var delta = $('.pagination', $self).first().data('delta') / 1;
						changePage($(this).val() / 1 - 1 + delta);
						return false;
					} else {
						$self.find('.navigation-go-to-page button').prop('disabled', !($(this).val() > 0));
					}
				}).on('submit', 'form', function () {
					loading.start();
				});
				$('.navigation-go-to-page input', $self).keyfilter(/[\d]/);
				if (options.ajax && options.url) {
					$self.ajaxForm({
						url: options.url,
						beforeSubmit: function () {
							loading.start();
						},
						success: function (html) {
							$('#ajax-box').html(html);
							loading.stop();
							init(options);
						}, error: function () {

							loading.stop();
							modal.alert('Błąd ładowania widoku ajaxowego');
						}
					})
				}
			} else {
				console.log('Nie ma zadeklarowanego id do listForm');
			}
		} else {
			console.log('Błąd inicjalizacji listForm')
		}
	}
	return {
		init
	}
});
