app.service('autocomplete', function ($global) {

	function autocomplete(option) {
		var options = {
			input: null,
			url: null,
			limit: 10,
			resultInput: null,
			minLength: 1,
			delay: 300,
			mustMatch: true
		};
		$.extend(options, option);
		if (options.input && options.url) {
			$(options.input).autocomplete(options.url, {
				max: options.limit,
				minChars: options.minLength,
				delay: options.delay,
				mustMatch: options.mustMatch,
				formatItem: function (data) {
					return data[1];
				},
				formatResult: function (data, value) {
					return data[1];
				}
			}).result(function (event, data, formatted) {
				if (data != null && data[0] != null) {
					$(options.resultInput).val(data[0]);
				} else {
					$(options.resultInput).val('');
				}
			}).blur(function () {
				if ($(this).val() == '') $(options.resultInput).val('');
			});
		}
	}
	function init(){
		$('.group-autocomplete, .filter-row-autocomplete').not(".init").each(function () {
			var $this = $(this);
			var $input = $this.find('input').eq(0);
			var id = $input.attr('id');
			id = id.substr(0, id.length - 13);
			autocomplete({
				url: $input.data('url'),
				input: '#'+id+'-autocomplete',
				resultInput: '#'+id,
				limit: 10,
				mustMatch: false
			});
			$this.addClass('init');
		});
	}
	$global('autocomplete', autocomplete);
	return {
		init
	}

});
