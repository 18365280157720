app.service('loading',function(console, $global, html){
	function start(){
		html.add('show-loading');
	}
	function stop(){
		html.remove('show-loading');
	}
	function redirect(data){
		start();
		document.location.href = data;
	}
	function reload(){
		start();
		location.reload(true);
	}

	$global('loadingStart', start);
	$global('loadingStop', stop);
	$global('redirect', redirect);
	$global('reload', reload);
	
	return {
		start,
		stop,
		redirect,
		reload
	};
});
