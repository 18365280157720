app.service('ajaxPage',function(listForm){
	var savedUrl, $self;

	function load(url){
		savedUrl = url;
		$self.addClass('loading').removeClass('error');
		$.ajax({
			url: $self.data('url'),
			success:function(html){
				$self.html(html);
				if($('#ajax-list-form').length){
					listForm.init({
						ajax:true, 
						id:'#ajax-list-form', 
						url: $self.data('url')
					});
				}
			},
			error:function(){
				$self.addClass('error').html('Błąd pobierania danych');
			},
			complete:function(){
				$self.removeClass('loading');
			}
		});
	}
	function reload(){
		if(savedUrl){
			load(savedUrl);
		}
	}
	function init(){
		$self = $('#ajax-box');
		if($self.length){
			load($self.data('url'));
		}
	}
	return {
		init, reload
	}
});
