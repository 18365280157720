app.service('console',function(){

	var savedLogs=[];

	function log(){
		var result=(arguments[1])?arguments:arguments[0];
		savedLogs.push(result);
		try{
			window.console.info(result);
		}catch(e){}
	}

	function list(){
		return savedLogs;
	}

	return {
		log,
		list
	};
});
