app.service('login', function (loading, validate){

	function init(){
		var $loginForm = $('#login-box form');
		if ($loginForm.length) {
			$loginForm.attr('action', $loginForm.data('url') + '?nocache=' + Math.random()).removeAttr('data-url');
			validate.task('consoleLogin', {
				form: $loginForm,
				success: function (form) {
					loading.start();
					form.submit();
				}
			});
		}
	}
	
	return {
		init
	}
});
