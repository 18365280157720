app.service('colorPicker',function(){

    function setEvents(){
        $('body').on('change keyup blur', '.group-input-color input', function(event){
            $(this).next().css('background',event.currentTarget.value);
        });
    }



    function init(){
        setEvents();
    }
    return {
        init
    }
});
