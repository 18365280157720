app.service('cookie',function(console) {

	function set(key,value,options) {
		$.cookie(key,value,options);
	}

	function get(key) {
		return $.cookie(key);
	}

	function isEmpty(key){
		return (get(key)===undefined || get(key)==='');
	}

	function remove(key,path='/'){
		$.removeCookie(key, {path:path});
	}

	function removeGlobal(key){
		remove(key, '/');
	}

	return {
		set,
		get,
		isEmpty,
		remove,
		removeGlobal
	};
});
