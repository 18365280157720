app.service('datelocal',function(html){

    function setEvents(){
        $('body').on('change', '.js-datelocal-date, .js-datelocal-time', function(){
            updateLocaltime(this);
        });
    }

    function updateLocaltime(elem) {
        let $parent = $(elem).closest('.js-datelocal-wrapper');
        let dateInputVal = $parent.find('.js-datelocal-date').val();
        let timeInputVal = $parent.find('.js-datelocal-time').val();
        let $input = $parent.find('.js-datelocal-input');

        if(dateInputVal && timeInputVal) {
            $input.val(`${dateInputVal}T${timeInputVal}`).change();
        } else if(dateInputVal) {
            $input.val(`${dateInputVal}`).change();
        }
    }

    function init(){
        setEvents();
    }
    return {
        init
    }
});
