app.service('dataList',function(){
    var result = [];

    function init($input, resultHtml = ''){
        let id = $input.attr('id');
        let $arrow =  $('<div class="datalist-status"></div>');
        let $result =  $('<div class="datalist"></div>');
        let options = resultHtml.split('|').map(y => y.trim()).filter(y => y.length);
        
        if(id && options.length){
            result[id]=options;
            $result.insertAfter($input);
            $arrow.insertAfter($result);
            $result.on('click', function(e){
                let value = e.target.innerHTML;
                $input.val(value);
                $result.empty();
            });
            $input.on('blur', function(){
                setTimeout(function(){
                    $result.empty();
                },200);
            });
            $result.addClass('has-datalist');
            $input.on('click focus input', function(){
                let value = $(this).val().toLowerCase();
                let y = result[id].filter(function(t){
                    return t.toLowerCase().indexOf(value) != -1;
                });
                $result.empty();
                if(y.length){
                    $result.addClass('has-datalist');
                    y.forEach(element => {
                        $('<div>'+element+'</div>').appendTo($result);
                    });
                } else {
                    $result.removeClass('has-datalist');
                }
            });
        }
    }
    return {
        init
    }
});
