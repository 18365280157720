app.service('menu', function (console){
	var $self;
	var isShow = false;

	function check() { 
		if (isShow) {
			show();
		} else {
			hide();
		}
	}
	function show($t) { 
		if (isShow) {
			$self.find('.show').removeClass('show');
			$self.find('.visible').removeClass('visible');
		}
		$t.parent().addClass('show');
		isShow = true;
		$self.addClass('active');
	}
	function hide() {
		$self.removeClass('active');
		$self.find('.show').removeClass('show');
		$self.find('.visible').removeClass('visible');
		isShow = false;
	}
	function init() {
		$self = $('#menu');
		$self.children('li').children('a').on('click', function () {
			if (isShow && $(this).parent('.show').length) {
				hide();
			} else {
				show($(this));
			}
		}).on('mouseover', function () { 
			if (isShow && !$(this).parent('.show').length) {
				show($(this));
			}
		}).next('ul').find('li').on('mouseover', function (event) {
			//showChildren();
			var $children = $(this).children('ul');
			$(this).parent().find('.visible').removeClass('visible');
			if ($children.length) {
				$(this).addClass('visible');
			} else {
				
			}
			event.stopPropagation();
		})
		$('body').on('click', function (e) { 
			if (isShow) {
				if (!$(e.target).closest('#menu').length) {
					
					hide();
				}
			}
		})
		
	}
	return {
		init
	}
});
