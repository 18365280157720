app.service('forgotPassword', function (front, validate, loading) {

    function init() {
        if (front.getPage() == 'forgot-password') {
            const $form = $('.forgot-password-box form');
            validate.task('consoleForgotPassword', {
                form: $form,
                success: function(form) {
                    loading.start();
                    form.submit();
                }
            });
        }
    }


    return {
        init
    }
})