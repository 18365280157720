app.service('flashMessage', function($global) {

	function globalFlashMessage(message) {

		localStorage.setItem('flashMessage', message);
	}

	function init() {

		var message = localStorage.getItem('flashMessage');

		if (message) {
			toastr.success(message);
			localStorage.removeItem('flashMessage');
		}
	}

	$global('flashMessage', globalFlashMessage);

	return {
		init : init
	}
});