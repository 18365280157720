app.service('scroll', function ($global) {
	var $menu, $top;
	function scrollTo($element, speed = 300, offsetTop = 10) {
		var scrollPosition = 0
		function calculateScrollPoint(){
			scrollPosition = $element.offset().top - $menu.height() - $top.height() + parseInt($body.css('padding-top')) + offsetTop;
		}
		if($element.length){
			calculateScrollPoint()
			$("body,html").animate({
				scrollTop: scrollPosition
			}, speed, function() {
				calculateScrollPoint()
				$("body,html").animate({
					scrollTop: scrollPosition
				}, 0);
			});
		}
	}
	function init(){
		$('body').on('click', '.js-scroll', function(event){
			var href = $(this).attr('href');
			var field = $(this).data('field');
			if(field){
				console.log('scroll do pola: '+field);
				scrollTo($('[name^="'+field+'"]'));
			}else if($(this).attr('href') != '#'){
				console.log('scroll do href: '+href);
				scrollTo($(href));
			}
			event.preventDefault();
		});
		$menu = $('#module-menu');
		$top = $('#top');
		$body = $('body');
	}
	$global('scrollTo', scrollTo)
	return {
		init
	}

});
