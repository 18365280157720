app.service('audit', function (modal, $global, dataList){
	var $modal, $input;
	var saveCallback=null;

	function init(){
		$('body').on('keydown', '#audit-text', function(event){
			if(event.keyCode == 13){
				save();
			}
		}).on('change', '#audit-text', function(event){
			$('#audit-box').removeClass('error');
		});
	}
	function save(){
		if ($input.val() == '') {
			$('#audit-box').addClass('error');
		}else {
			$('#audit-box').removeClass('error');
			close();
			saveCallback($input.val());
		}
	}

	function close(){
		modal.close();
		if(closeCallback){
			closeCallback();
		}
	}
	function open(c1, c2){
		saveCallback=c1;
		closeCallback=c2;
		$modal = modal.generate({
			id: 'modal-audit',
			header: "Proszę o podanie powodu zmiany",
			close:false,
			callbackOpen:function(){
				$input = $('#audit-text');
				$input.focus().click();
				dataList.init($input, $('#audit-reasons').html());
			},
			content: `<div class="row" id="audit-box">
					<label>Komentarz</label>
					<div>
						<input id="audit-text" autofocus type="text" value="" list="possible-reason-for-change"/>
					</div>
				</div>`,
			buttons:[
				{
					text: 'Zapisz',
					click: function(){
						save();
					}
				},{
					text: 'Anuluj',
					click: function(){
						close();
					}
				}
			]
		});
	}

	$global('audit', open);
	return {
		init, open
	}
});
