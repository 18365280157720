app.service('editor',function($global, html){
	function init($parent = $('body')) {
		$('textarea[syntax], textarea.syntax', $parent).each(function(){
			var $textarea = $(this);

			var mode=$textarea.attr('syntax');
			if(mode==="true" || mode==="default"){
				mode="text/html";
			}

			var editor = CodeMirror.fromTextArea($textarea.get(0), {
				mode : mode,
				lineNumbers: true,
				readOnly: $textarea.closest('.read-mode').length>0,
				lineWrapping: true,
				foldGutter: true,
				firstLineNumber: $textarea.data('first-line-number')|| 1,
				gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
				extraKeys : {
					"Ctrl-Space" : "autocomplete",
					"F11": function (cm) {
						html.toggle('full-screen-editor');
						cm.setOption("fullScreen", !cm.getOption("fullScreen"));
					},
					"Esc" : function(cm) {
						if (cm.getOption("fullScreen")) {
							html.remove('full-screen-editor');
							cm.setOption("fullScreen", false);
						}
					}
				}
			});
			editor.on("blur", function(cm) {
				$textarea.val(cm.getValue());
			});
			var inter;
			function isVisible() { 
				return parseInt($textarea.css('height')) > 0;
			}
			if (!isVisible()) {
				inter = setInterval(function () {
					if (isVisible()) {
						editor.refresh();
						clearInterval(inter);
					}
				}, 200);
			}
			
		});
	}
	return {
		init
	}
});
