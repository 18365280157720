app.service('filters',function(html){
	var $self;

	function setElements(){
		$self=$('#filters');
	}
	function setEvents(){
		
	}
	function init(){

	}
	return {
		init
	}
});
